<template>
  <div class="container-fluid">
    <div class="d-flex justify-content-center align-items-center" style="height:100vh" v-if="loading">
      <span
        class="spinner-border spinner-border-lg"
      ></span>
    </div>
    <div class="row" v-else>
      <div class="row g-2">
        <header class="jumbotron">
        <h2>Liga 1v1</h2>
        <div class="row">
          <div class="col-md-2">
            <select id="leagueSelect" class="form-select" v-model="dropDownSelect" @change="refreshLeagueInfo">
              <option v-for="j in leaguesList" :key="j._id" :value="j._id">
                {{j.name}} - Temporada {{j.season}}
              </option>
            </select>
          </div>
        </div>
      </header>
      </div>
      <div class="row g-3">
        <div class="col-md-5">
          <table class="table table-sm table-stripped table-hover">
            <thead>
              <tr>
                <th style="width: 30px;">#</th>
                <th style="width: 160px;">Jogador</th>
                <th style="width: 50px;">Pontos</th>
                <th v-for="(j, index) in players" :key="index" style="text-align: center;">{{index + 1}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(j, index) in players" :key="index">
                <td style="width: 30px;">{{index +1}}</td>
                <td style="width: 160px;">{{j.username}}</td>
                <td style="width: 50px; text-align: center;">{{j.points || 0}}</td>
                <td style="text-align: center; border: 1px solid #dee2e6;" v-for="(i, otherIndex) in j.results" :key="otherIndex">
                  <span>{{i}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-7">
          <div v-if="currentUser && !signedIn && league.status == 'created'">
            <div class="card text-center">
              <div class="card-body">
                <button type="button" class="btn btn-lg btn-success" @click="joinLeague">
                  Inscrever {{selectedLeague.name}} Temporada {{selectedLeague.season}}</button>
              </div>
            </div>
          </div>
          <div v-if="currentUser && signedIn && league.status == 'created'">
            <div class="card text-center">
              <div class="card-body">
                <p>Já estás inscrito na liga. Ainda não começou.</p>
                <button type="button" class="btn btn-sm btn-danger" @click="leaveLeague">Sair</button>
              </div>
            </div>         
          </div>
          <div v-if="league && league.status != 'created'">
            <div class="games">
              <h3>Jogos a decorrer</h3>
              <table class="table table-sm table-stripped table-hover">
                <thead class="thead-dark">
                  <tr>
                    <th style="min-width: 100px;">Nome</th>  
                    <th>Gen</th>
                    <th>Jog 1</th>
                    <th>Jog 2</th>
                  </tr>
                </thead> 
                <tbody>
                  <tr class="clickable" v-for="game in ongoingGames" v-bind:key="game._id" @click="openGame(game)">
                    <td>{{game.name}}</td> 
                    <td>{{game.generation}}</td>
                    <td><font-awesome-icon icon="user" v-if="game.player_id && game.player_username == game.p1_username" />
                      {{game.p1_username}}
                      <span style="font-size: 0.7em;" v-show="game.p1_points"> ({{game.p1_points}})</span></td>
                    <td><font-awesome-icon icon="user" v-if="game.player_id && game.player_username == game.p2_username" />
                      {{game.p2_username}}
                      <span style="font-size: 0.7em;" v-show="game.p2_points"> ({{game.p2_points}})</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-if="league && league.status != 'created'"  class="col-md-12">
          <div>
            <h3>Jogos terminados </h3>
            <table class="table table-sm table-stripped table-hover">
              <thead class="thead-dark">
                <tr>
                  <th style="min-width: 100px;">Nome</th>  
                  <th>Gen</th>
                  <th>Jog 1</th>
                  <th>Jog 2</th>
                </tr>
              </thead> 
              <tbody>
                <tr class="clickable" v-for="game in finishedGames" v-bind:key="game._id" @click="openGame(game)">
                  <td>{{game.name}}</td> 
                  <td>{{game.generation}}</td>
                  <td><font-awesome-icon icon="user" v-if="game.player_id && game.player_username == game.p1_username" />
                    {{game.p1_username}}
                    <span style="font-size: 0.7em;" v-show="game.p1_points"> ({{game.p1_points}})</span></td>
                  <td><font-awesome-icon icon="user" v-if="game.player_id && game.player_username == game.p2_username" />
                    {{game.p2_username}}
                    <span style="font-size: 0.7em;" v-show="game.p2_points"> ({{game.p2_points}})</span></td>
                </tr>
              </tbody>
            </table>
            <template v-for="(round,index) in numberOfRounds" :key="index">
              <button type="button" 
                @click="selectedRound = round"
                :class="['btn','btn-sm', selectedRound == round ? 'btn-primary' : 'btn-secondary']">Ronda {{round}}</button>&nbsp;
            </template>
          </div>
        </div>
      </div>  
    </div>
  </div>
</template>

<script>
import LeagueService from "../services/league.service";
import util from '../util';

export default {
  name: "League",
  data() {
    return {
      loading: true,
      leaguesList: [],
      selectedLeague: {},
      league: {},
      showInfo: false,
      playersDetail: false,
      selectedRound: 1,
      maxRounds: 1,
      dropDownSelect: null
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    signedIn (){
      return this.currentUser && this.league && this.league.status == 'created' && this.league.players && this.league.players.find(p => p.user_id == this.currentUser.user_id);
    },
    players(){
      if(this.league?.players)
        return this.league.players;
      else 
        return [];
    },
    ongoingGames(){
      if(this.league?.games)
        return this.league.games.filter(g => g.status != 'end');
      else 
        return [];
    },
    finishedGames(){
      if(this.league?.games)
        return this.league.games.filter(g => g.round_id == this.selectedRound && g.status == 'end');
      else 
        return [];
    },
    numberOfRounds(){
      return Array.from({length: this.maxRounds}, (_, i) => i + 1)
    }
  },
  created() {
    this.refreshPage();
  },
  methods: {
    async refreshPage() {
      if(this.selectedLeague._id){
        this.loading = true;
        this.dropDownSelect = this.selectedLeague._id;
        await this.refreshLeagueInfo();
      } else {
        let allLeagues = await LeagueService.getAllLeagues();
        this.leaguesList = allLeagues.data.filter(x => x.name == 'Liga 1v1');
        //by default the selected is either the created one or the running one (only 1 of those at any time)
        
        this.selectedLeague = this.leaguesList.find(x => x.status == 'created' || x.status == 'running');
        if(!this.selectedLeague) 
        {
          var league = Math.max(...this.leaguesList.map(o => o._id))
          this.selectedLeague = this.leaguesList.find(x => x._id == league);
        
        }
        this.dropDownSelect = this.selectedLeague._id;
        await this.refreshLeagueInfo();
      }      
    },
    async refreshLeagueInfo(){
      let response = await LeagueService.get1v1LeagueInfo(this.dropDownSelect)
      this.league = response.data;
      this.loading = false;

      this.maxRounds = this.league.games.reduce((prev, curr) => {
        return curr.round_id > prev ? curr.round_id : prev;
      }, 1);
      this.selectedRound = this.maxRounds;
    },
    showDetails() {
      this.playersDetail = !this.playersDetail;
    },
    joinLeague(){
      LeagueService.joinCurrentLeague(this.currentUser.user_id).then(() => this.refreshPage());
    },
    leaveLeague(){
      LeagueService.leaveCurrentLeague(this.currentUser.user_id).then(() => this.refreshPage());
    },
    openGame(game){ 
      let url = game.player_id ? `/player?id=${game.player_id}` : `/spectator?id=${game.spectator}`;
      let g_url = new URL(game.url); 
      window.open(g_url.origin + url, '_blank');
    },
    deltaToNow: util.deltaToNow,
  }
};
</script>

<style scoped>
  .table{
    font-size: 12px
  } 

  .highlight {
    font-weight: bold;
  }  
  
  .clickable {
    cursor: pointer;
  }

  .row-active {
    background-color: rgb(175, 224, 175);
  }

  .row-active:hover {
    background-color: rgb(24, 110, 24);
  }

  .link {
    color: #0d6efd;
    cursor: pointer;
  }
</style>